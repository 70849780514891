.hero-container {
  max-width: 800px;
  margin: 120px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;

  h1 {
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
  }

  form {
    margin-bottom: 20px;
    

    input[type="text"] {
      width: 50%;
      padding: 10px;
      font-size: 16px;
      border: 1px solid #ccc;
      border-radius: 5px;
    }

    button[type="submit"] {
      width: 20%;
      padding: 10px;
      font-size: 16px;
      background-color: #4CAF50;
      color: #fff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      margin-left: 10px;
    }
  }

  .error {
    color: red;
    margin-bottom: 20px;
  }

  .definitions {
    margin-top: 20px;

    h2 {
      margin-bottom: 10px;
    }

    .definition {
      margin-bottom: 20px;
      padding: 10px;
      border-bottom: 1px solid #ccc;

      h3 {
        margin-bottom: 5px;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          margin-bottom: 5px;
        }
      }
    }
  }

  .view-all-words {
    margin-top: 20px;
    text-align: center;

    button {
      padding: 10px 20px;
      font-size: 16px;
      background-color: #4CAF50;
      color: #fff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}