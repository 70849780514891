.all-words-container {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center; // Add this line to center the text
  
    h1 {
      margin-bottom: 20px;
    }
  
    .word-list {
      list-style: none;
      padding: 0;
      margin: 0;
  
      li {
        margin-bottom: 10px;
        padding: 10px;
        border-bottom: 1px solid #ccc;
  
        &:last-child {
          border-bottom: none;
        }
  
        .word {
          font-weight: bold;
          margin-bottom: 5px;
        }
      }
    }
  }