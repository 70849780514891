.Header{
    

    background-color: white;
        // backdrop-filter: blur(10px);
        // -webkit-backdrop-filter: blur(10px);
      
        // border:1px solid rgba(255, 255, 255, 0.18);
        // box-shadow: 0 8px 32px 0 rgba(219, 214, 214, 0.37);
        
          
        
    
    nav{
    
        align-content: center;
    
        padding: 25px;
        width: 70%;
        margin: auto;
        display: flex;
        justify-content: space-between;
    
    
        ul{
    
         
            gap:4rem ;
           
       
            display: flex;
          
    
    
            li{
    
                
                list-style: none;
               
    
    
    
    
                a{
                    color: black;
                    text-decoration: none;
                    font-size: .875rem;
    
    
                    
    
                }
            }
        }
    }
    }
    
    .botton{
        border: 1px solid black;
        background-color: black;
        color: white !important;
        text-align: center;
        border-radius: 12px;
        padding: 0.75rem 1.25rem;
    }
    
    .h-signin{
        border-left: .5px solid black;
        padding-left: 20px;
    
    }
    
    .Header-wrapper{
        position: fixed;
        width: 100%;
        top: 0px;
        z-index: 1;
    }
    
    
    @media only screen and (max-width: 767px) {
        .Header-wrapper {
          display: none;
        }
      }
    
      .logo{
        color: rgb(27, 22, 17);
        font-size: 20px;
        font-weight: 600;
      }